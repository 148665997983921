.App{
  text-align: center;
  background-size: cover;
  height: screen;
}

.App-header {
  color: white;
  font-family: 'Quicksand', sans-serif;
}
.login-button{
  border: 0.5px solid #C9A075;
}
.card{
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
                inset 2px 2px 2px -1px rgba(160, 160, 160, 0.466);
    background: rgba(255, 249, 238, 0.363);
}
.footer {
  display: flex;
  bottom: 0;
  align-items: center;
  justify-content: space-between;
  position: relative;
}