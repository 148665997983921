body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  height: screen;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e8e8e6;
  background-color: '#E8E8E6';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Belleza&family=Quicksand:wght@300;400;700&display=swap')